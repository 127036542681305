import { notification } from 'antd';
import * as APIBooking from '../api/booking';
import { BookingType } from '../types/booking';
import { types } from '../types/types';
import { startLoading as StartUiLoading, finishLoading as finishUiLoading } from './ui';

export const startLoading = () => ({
    type: types.ridesStartLoading,
});

export const finishLoading = () => ({
    type: types.ridesFinishLoading,
});

export const setActive = (ride: BookingType) => ({
    type: types.ridesSetActive,
    payload: ride,
});

export const setActiveEvents = (ride: BookingType) => ({
    type: types.ridesSetActiveEvents,
    payload: ride,
});

export const updateProviderRide = (provider: string) => ({
    type: types.ridesUpdateProviderRide,
    payload: provider,
});

export const updateSmallFleetProvider = (sfProvider: string) => ({
    type: types.ridesUpdateSmallFleetProviderRide,
    payload: sfProvider,
});

// eslint-disable-next-line no-unused-vars
export const startLoadRide = (id: string) => async (dispatch: (f: any) => any) => {
    dispatch(StartUiLoading());
    try {
        const { data } = await APIBooking.getBookingDetails(id);
        dispatch(setActive(data.data));
        const { data: events } = await APIBooking.getBookingEvents(id);
        dispatch(setActiveEvents(events.data));
    } catch (e: any) {
        console.log(e);
        notification.error({ message: e.response?.data?.message });
    } finally {
        dispatch(finishUiLoading());
    }
};

// eslint-disable-next-line no-unused-vars
export const startUpdateRide = (bookingID: string, data: any) => async (dispatch: (f: any) => any) => {
    dispatch(startLoading());
    try {
        await APIBooking.updateBooking({ bookingID, ...data });
        dispatch(updateProviderRide(data.selectedProvider));
        data.selectedProvider === 'SMALL_FLEETS' && dispatch(updateSmallFleetProvider(data.driverTag));
        notification.success({
            message: 'Booking actualizado!',
        });
    } catch (e: any) {
        console.log(e);
        notification.error({ message: e.response?.data?.message });
    } finally {
        dispatch(finishLoading());
    }
};
