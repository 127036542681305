import {
    Button,
    PageHeader,
    Table,
    Tag,
    Typography,
} from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { FaSync } from 'react-icons/fa';
import { EDOrder } from '../../../types/express-delivery';
import { getStatusChipClass } from '../../../utils/bookings';
import { getGuideOrders } from '../../../api/express-delivery';

const { Title } = Typography;
interface GuideOrderTableProps {
    guideId: string;
}

export const GuideOrderTable = ({ guideId }: GuideOrderTableProps) => {
    const [loading, setLoading] = useState(false);
    const [orders, setOrders] = useState<EDOrder[]>([]);

    const fetchOrderUpdates = () => {
        setLoading(true);
        getGuideOrders(guideId)
            .then(({ data }) => {
                setOrders(data.data);
            })
            .catch((error) => console.log(error))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (!guideId) return;
        fetchOrderUpdates();
    }, [guideId]);

    const columns = [
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'date',
            render: (date: string) => moment(date).local().format('DD-MM-YYYY HH:mm:ss'),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status: string) => (
                <Tag {...getStatusChipClass(status)}>{status}</Tag>
            ),
        },
        {
            title: 'Provider',
            dataIndex: 'providerName',
            key: 'providerName',
        },
        {
            title: 'Error Message',
            dataIndex: 'errorMessage',
            key: 'errorMessage',
        },
        {
            title: 'Tracking',
            dataIndex: 'trackingUrl',
            key: 'trackingUrl',
            render: (trackingUrl: string) => (
                <span>
                    {trackingUrl ? (
                        <a href={trackingUrl} target="_blank" rel="noopener noreferrer">
                            See tracking
                        </a>
                    ) : (
                        'Not available'
                    )}
                </span>
            ),
        },
        {
            title: 'Provider Fee',
            dataIndex: 'providerFee',
            key: 'providerFee',
            render: (providerFee: number) => `$${(providerFee || 0).toFixed(2)}`,
        },
    ];

    return (
        <PageHeader
            title={(
                <Title level={5} style={{ marginBottom: 0 }}>
                    Orders guide
                </Title>
            )}
            extra={[
                <Button type="link" onClick={fetchOrderUpdates}>
                    <FaSync />
                </Button>,
            ]}
            style={{ padding: 0 }}
            footer={(
                <Table
                    columns={columns}
                    dataSource={orders}
                    pagination={false}
                    loading={loading}
                />
            )}
        />
    );
};
