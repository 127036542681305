/* eslint-disable prefer-destructuring */
import {
    createContext,
    FC,
    useEffect,
    useState,
} from 'react';
import { notification } from 'antd';
import { IContext, ISharedMonitor } from './datadogMonitor.interface';
import initialContextData from './datadogMonitor.initial';
import { getDatadogSharedDashboards } from '../../../api/analytics';

export const DatadogMonitorContext = createContext<IContext>(initialContextData);

export const DatadogMonitorProvider: FC = ({ children }) => {
    const [initialContext] = useState<IContext>(initialContextData);
    const [loading, setLoading] = useState<boolean>(initialContext.loading);
    const [sharedMonitors, setSharedMonitors] = useState<ISharedMonitor[]>(initialContext.sharedMonitors);
    const [selectedMonitors, setSelectedMonitors] = useState<ISharedMonitor[]>(initialContext.selectedMonitors);
    const [isOpenInfo, setIsOpenInfo] = useState<boolean>(initialContext.isOpenInfo);

    const selectMonitor = (monitorIds: string[]) => {
        const monitors = sharedMonitors.filter((monitor) => monitorIds.includes(monitor._id));

        localStorage.setItem('selectedMonitors', JSON.stringify(monitorIds));

        setSelectedMonitors(monitors);
    };

    const fetchSharedMonitors = async () => {
        setLoading(true);

        getDatadogSharedDashboards()
            .then(({ data }) => {
                setSharedMonitors(data.data);

                const selectedMonitorsIDS = JSON.parse(localStorage.getItem('selectedMonitors') || '[]');

                const monitors = data.data.filter((monitor: ISharedMonitor) => selectedMonitorsIDS.includes(monitor._id));

                setSelectedMonitors(monitors);
            })
            .catch(({
                response: { data: { message } },
            }) => {
                notification.error({ message });
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        fetchSharedMonitors();
    }, []);

    return (
        <DatadogMonitorContext.Provider
            value={{
                loading,
                sharedMonitors,
                selectedMonitors,
                isOpenInfo,
                selectMonitor,
                setIsOpenInfo,
            }}
        >
            {children}
        </DatadogMonitorContext.Provider>
    );
};
