import moment from 'moment';
import {
    Button,
    Dropdown,
    Menu,
    PageHeader,
    Table,
    Modal,
    Typography,
    Col,
    Row,
    Input,
    notification,
} from 'antd';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { BsThreeDots } from 'react-icons/bs';

import { deletePlan, duplicatePlan, getWorkspacesByPlan } from '../../api/payments';
import PlanEvalModal from '../Modals/PlanEvalModal';
import PlanModal from '../Modals/PlanModal';
import { PlanContext } from './context/PlanContext';
import { Plan } from '../../interfaces/plan';

const { Text } = Typography;
const { Search } = Input;

export const Plans = () => {
    const [plan, setPlan] = useState<Plan>();
    const [testPlan, setTestPlan] = useState(false);
    const [addPlan, setAddPlan] = useState(false);
    const {
        plans, loading, total, fetchPlans, paginator, handlePaginator, setPaginator, setPlans,
    } = useContext(PlanContext);

    const menu = (plan: string) => (
        <Menu>
            <Menu.ItemGroup key="g">
                <Menu.Item
                    onClick={() => {
                        setPlan(plans.find((p) => p._id === plan));
                        setAddPlan(true);
                    }}
                >
                    Edit Plan
                </Menu.Item>
                <Menu.Item
                    onClick={() => {
                        setPlan(plans.find((p) => p._id === plan));
                        setTestPlan(true);
                    }}
                >
                    Test Plan
                </Menu.Item>
                <Menu.Item
                    onClick={() => {
                        Modal.confirm({
                            title: 'Are you sure you want to duplicate this plan?',
                            okText: 'Yes',
                            cancelText: 'No',
                            async onOk() {
                                await duplicatePlan(plan);
                                fetchPlans();
                            },
                            onCancel() {},
                        });
                    }}
                >
                    Duplicate
                </Menu.Item>

                <Menu.Item
                    onClick={async () => {
                        const { data } = await getWorkspacesByPlan(plan);
                        Modal.confirm({
                            title: 'Are you sure you want to delete this plan?',
                            content: (
                                <div>
                                    <Text strong>Workspaces:</Text>
                                    <ul>
                                        {data.data.map((workspace: any) => (
                                            <li key={workspace._id}>
                                                {workspace.name}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ),
                            okText: 'Yes',
                            cancelText: 'No',
                            async onOk() {
                                await deletePlan(plan);
                                fetchPlans();
                                notification.success({
                                    message: 'Plan deleted successfully',
                                });
                            },
                            onCancel() {},
                        });
                    }}
                    danger
                >
                    Delete
                </Menu.Item>
            </Menu.ItemGroup>
        </Menu>
    );

    const handleCancelPlan = (plan: any) => {
        if (plan) {
            setPlans((plans: Plan[]) => {
                const index = plans.findIndex((p: any) => p._id === plan._id);
                if (index !== -1) {
                    plans[index] = plan;
                } else {
                    plans.push(plan);
                }
                return [...plans];
            });
        }
        setAddPlan(false);
        setPlan(undefined);
    };

    const onPageChange = (page: number, pageSize: number) => {
        setPaginator({ limit: pageSize, page });
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (name: string, plan: any) => (
                <Link style={{ color: '#08d108' }} to={`/v2/plans/${plan._id}`} state={plan}>
                    {name}
                </Link>
            ),
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt: string) => (
                <Text>
                    {moment(createdAt).format('MMM DD MMM')}
                </Text>
            ),
        },
        {
            title: 'Frequency',
            dataIndex: 'frequency',
            key: 'frequency',
            render: (frequency: string) => (
                <Text>
                    {frequency || 'Not available'}
                </Text>
            ),
        },
        {
            title: '',
            dataIndex: '_id',
            key: 'actions',
            width: 60,
            fixed: 'right' as 'right',
            // eslint-disable-next-line react/display-name
            render: (_id: string) => (
                <Dropdown
                    placement="bottomRight"
                    overlay={() => menu(_id)}
                    // @ts-ignore
                    onClick={(e: any) => { e.stopPropagation(); }}
                >
                    <div style={{ textAlign: 'center' }}>
                        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                            <BsThreeDots size={25} />
                        </a>
                    </div>
                </Dropdown>
            ),
        },
    ];

    return (
        <div>
            <PageHeader
                title="Plans"
                style={{ padding: '24px 0' }}
                extra={[
                    <Row justify="end" gutter={16}>
                        <Col>
                            <Search
                                placeholder="Search..."
                                onChange={(search) => {
                                    handlePaginator({ ...paginator, page: 1, search: search.target.value });
                                }}
                                defaultValue={paginator.search}
                                enterButton
                                allowClear
                            />
                        </Col>
                        <Col>
                            <Button key="1" type="primary" onClick={() => setAddPlan(true)}>
                                Add Plan
                            </Button>
                        </Col>
                    </Row>,
                ]}
            />
            <PlanEvalModal
                visible={!!testPlan}
                plan={plan}
                onCancel={() => setTestPlan(false)}
            />
            <PlanModal
                plan={plan}
                visible={addPlan}
                onCancel={(plan) => handleCancelPlan(plan)}
            />
            <Table
                columns={columns}
                dataSource={plans}
                loading={loading}
                rowKey="_id"
                pagination={{
                    total,
                    current: paginator.page,
                    pageSize: paginator.limit,
                    onChange: onPageChange,
                    showSizeChanger: true,
                }}
            />
        </div>
    );
};
