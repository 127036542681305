import { useContext } from 'react';
import {
    Drawer,
    Space,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import Information from '../../ExpressDelivery/components/Information';
import { GuidesContext } from '../context/guides.context';
import { GuideOrderTable } from '../../ExpressDelivery/components/DeliveryOrderTable';
import MetaData from './MetaData';
import Deliveries from './Deliveries';
import StatusUpdates from './StatusUpdates';

const GuideDetail = () => {
    const navigate = useNavigate();

    const { itemSelected, setItemSelected } = useContext(GuidesContext);

    if (!itemSelected) return null;

    return (
        <Drawer
            title={`Guide Detail - ${itemSelected.guideNumericId}`}
            open={!!itemSelected}
            onClose={() => {
                setItemSelected(null);
                navigate('/v2/express-delivery');
            }}
            width={900}
        >
            <Space direction="vertical" size={40} style={{ width: '100%' }}>
                <Information
                    createdAt={itemSelected.createdAt}
                    businessName={itemSelected.businessName}
                    businessID={itemSelected.businessID}
                    workspaceID={itemSelected.workspaceID}
                    workspaceName={itemSelected.workspaceName}
                    deliveryProvider={itemSelected.deliveryProvider || 'Not assigned'}
                    driverName={itemSelected.driverName || 'Not assigned'}
                    driverMobile={itemSelected.driverMobile || 'Not assigned'}
                />
                <MetaData
                    header="Meta Data"
                    estimatedDeliveryTime={itemSelected.estimatedDeliveryTime || 0}
                    totalDistance={itemSelected.totalDistance || 0}
                    providerFee={itemSelected.providerFee || 0.00}
                />
                <Deliveries itemSelected={itemSelected} />
                <GuideOrderTable guideId={itemSelected._id} />
                <StatusUpdates
                    statusUpdates={itemSelected.statusUpdates || []}
                    expressDeliveries={itemSelected.expressDeliveries}
                />
            </Space>
        </Drawer>
    );
};

export default GuideDetail;
