import { Tabs } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { PlanProvider } from '../../components/Plans/context/PlanContext';
import { Plans } from '../../components/Plans/Plans';
import { VariableTable } from '../../components/Plans/VariableTable';
import { VariableProvider } from '../../components/Plans/context/VariableContext';

const { TabPane } = Tabs;

const PlansScreen = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const tab = searchParams.get('tab') || 'plans';

    const handleTabChange = (key: string) => {
        searchParams.set('tab', key);
        setSearchParams(searchParams);
    };

    return (
        <Tabs defaultActiveKey={tab} onTabClick={handleTabChange}>
            <TabPane tab="Plans" key="plans">
                <PlanProvider>
                    <Plans />
                </PlanProvider>
            </TabPane>
            <TabPane tab="Variables" key="variables">
                <VariableProvider>
                    <VariableTable />
                </VariableProvider>
            </TabPane>
        </Tabs>
    );
};

export default PlansScreen;
