import {
    Card,
    Col, Row, Select, Typography,
} from 'antd';
import { useContext } from 'react';
import { DatadogMonitorContext } from '../context/datadogMonitor.context';
import MonitorEmbedElement from './MonitorEmbedElement';

const { Text } = Typography;

const MonitorElementsContent = () => {
    const {
        sharedMonitors, loading, selectedMonitors, selectMonitor,
    } = useContext(DatadogMonitorContext);

    return (
        <>
            <Select
                mode="multiple"
                placeholder="Select a monitor"
                onChange={(value) => selectMonitor(value)}
                style={{ width: 600, marginBottom: 16 }}
                loading={loading}
                value={selectedMonitors.map((monitor) => monitor._id)}
            >
                {sharedMonitors.map((monitor) => (
                    <Select.Option key={monitor._id} value={monitor._id}>
                        {monitor.title}
                    </Select.Option>
                ))}
            </Select>
            <Row
                align="top"
                justify="space-between"
                gutter={[16, 16]}
            >
                {selectedMonitors.map((monitor) => (
                    <MonitorEmbedElement
                        key={monitor._id}
                        monitor={monitor}
                    />
                ))}
            </Row>

        </>
    );
};

export default MonitorElementsContent;
