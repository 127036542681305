import {
    Space,
    Row,
    Col,
} from 'antd';
import { FC } from 'react';
import { ISharedMonitor } from '../context/datadogMonitor.interface';

interface MonitorEmbedElementProps {
    monitor: ISharedMonitor;
}

const MonitorEmbedElement: FC<MonitorEmbedElementProps> = ({
    monitor,
}) => (
    <Col
        xs={24}
        md={12}
    >
        <embed
            src={monitor.publicUrl}
            width="100%"
            style={{ height: '75vh' }}
        />
    </Col>
);

export default MonitorEmbedElement;
