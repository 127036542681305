import {
    createContext, Dispatch, FC, SetStateAction, useEffect, useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { debounce } from 'lodash';
import { Plan } from '../../../interfaces/plan';
import { getAccountingPlans } from '../../../api/payments';
import { OctopusPaginatorQuery } from '../../../types/paginator';

interface IPlanContext {
    plans: Plan[],
    loading: boolean,
    total: number;
    paginator: OctopusPaginatorQuery;
    fetchPlans: () => void,
    setPaginator: (paginator: OctopusPaginatorQuery) => void,
    handlePaginator: (newPaginator: OctopusPaginatorQuery) => void,
    setPlans: Dispatch<SetStateAction<Plan[]>>,
}

const initialContext = {
    plans: [],
    loading: false,
    total: 0,
    paginator: { limit: 10, page: 1 },
    fetchPlans: () => {},
    handlePaginator: () => {},
    setPaginator: () => {},
    setPlans: () => {},
};

export const PlanContext = createContext<IPlanContext>(initialContext);

export const PlanProvider: FC = ({ children }) => {
    const [loading, setLoading] = useState<boolean>(initialContext.loading);
    const [plans, setPlans] = useState<Plan[]>(initialContext.plans);
    const [total, setTotal] = useState(0);

    const [searchParams, setSearchParams] = useSearchParams();
    const search = searchParams.get('search') || '';
    const page = searchParams.get('page') || '1';
    const limit = searchParams.get('limit') || '10';
    const [paginator, setPaginator] = useState<OctopusPaginatorQuery>({ limit: Number(limit), page: Number(page) });

    const fetchPlans = () => {
        setLoading(true);
        getAccountingPlans({ paginator, search })
            .then(({ data }) => {
                setPlans(data.data.data);
                setTotal(data.data.total);
            })
            .catch()
            .finally(() => setLoading(false));
    };

    const handlePaginator = (newPaginator: OctopusPaginatorQuery) => {
        const paginationUpdated = { ...paginator, ...newPaginator };
        const params = new URLSearchParams({ ...(paginationUpdated as any) });
        setPaginator(paginationUpdated);
        setSearchParams(params);
    };

    const fetchDebounced = debounce(fetchPlans, 700);

    useEffect(() => {
        fetchDebounced();
        return () => {
            fetchDebounced.cancel();
        };
    }, [paginator, search]);

    return (
        <PlanContext.Provider
            value={{
                plans,
                loading,
                total,
                paginator,
                fetchPlans,
                handlePaginator,
                setPaginator,
                setPlans,
            }}
        >
            {children}
        </PlanContext.Provider>
    );
};
